$body-bg: #fff;
$gray-bg: #EBF0FF;
$orange:rgba(253,102,28,1);
$menuHover:#FE972F;
$primary-color: #24a0ed;
$primary_btn_color:linear-gradient(60deg, rgba(253,102,28,1) 22%, rgba(254,151,47,1) 93%);
$dark_blue:#0B1C61;
$light_blue:#1A2F89;
$tertiaryButton:linear-gradient(22deg, #1A2F89 22%, #2343CC 93%);
$darkBlueGradient:linear-gradient(to right top, #2343cc, #213fbf, #1f3cb2, #1d38a6, #1c3499, #1b3294, #1b318e, #1a2f89, #1a2f89, #1a2f89, #1a2f89, #1a2f89);
$darkBlueGradientRevise:linear-gradient(to left top, #2343cc, #213fbf, #1f3cb2, #1d38a6, #1c3499, #1b3294, #1b318e, #1a2f89, #1a2f89, #1a2f89, #1a2f89, #1a2f89);

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body{
    font-family: "Poppins", sans-serif;
}
a{
    text-decoration: none;
}
ul{
    list-style:none;
    padding: 0px;
    margin: 0px;
}
h2{
    font-size: 60px;
    font-weight: 700;
}
p{
    font-size: 20px;
    color: $dark_blue;
}
.form-select{
    height: 50px;
    font-size: 18px;
    background-color: #EBF0FF;
    border: 1px solid #7B91EB;
    background-image: url('../images/arrow-down-g.svg');
} 
.formCustomRadio{
    input{
        opacity: 0;
        &:checked+label{
            color: #2343CC;
            &:before{
                border: 2.5px solid #2343CC; 
            }
            &:after{
                transform: scale(1);
            }
        }
    }
    label{
        font-size: 18px;
        color: #7A7B7E;
        position: relative;
        margin-left: 15px;
        cursor: pointer;
        &:before{
            content: "";
            width: 22px;
            height: 22px;
            position: absolute;
            border-radius: 50%;
            left: -34px;
            border: 2px solid #7A7B7E;
            top: 3px;
        }
        &:after{
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            border-radius: 50%;
            background: #2343CC;
            top: 9px;
            left: -28px;
            transform: scale(0);
        }
    }
}

.customCheckbox{
    input{
        opacity: 0;
        &:checked+label{
            color: #2343CC;
            &:before{
                border: 2.5px solid #2343CC; 
                background-color:#2343CC ;
            }
            &:after{
                transform: scale(1);
            }
        }
    }
    label{
        font-size: 18px;
        color: #7A7B7E;
        position: relative;
        margin-left: 15px;
        cursor: pointer;
        &:before{
            content: "";
            width: 22px;
            height: 22px;
            position: absolute;
            border-radius: 5px;
            left: -34px;
            border: 2px solid #7A7B7E;
            top: 3px;
        }
        &:after{
            content: "";
            width: 16px;
            height: 12px;
            position: absolute;
            border-radius: 50%;
            background: url('../images/check-white.svg');
            top: 9px;
            left: -28px;
            background-size: 12px;
            background-repeat: no-repeat;
            transform: scale(0);
        }
    }
}

.customImgCheckbox{
    input{
        opacity: 0;
        &:checked+label{
            color: #fff;
            background-image: $darkBlueGradientRevise;
            span{
                background-color: #fff;
                .iconHOver{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    label{
        font-size: 18px;
        position: relative;
        margin-left: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #7B91EB;
        border-radius: 10px;
        padding: 15px;
        color: #2343CC;
        span{
            display: flex;
            width: 40px;
            background: #2343CC;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin-right: 15px;
            position: relative;
            .iconHOver{
                position: absolute;
                opacity: 0;
                visibility: hidden;
            }
        }
        
        
    }
}


.primary_btn {
    font-size: 20px;
    color: #fff;
    height: 50px;
    min-width: 164px;
    text-align: center;
    line-height: 29px;
    display: inline-block;
    border-radius: 50px;
    padding: 10px;
    background:$primary_btn_color;
    transition: all 0.5s;
    font-weight: 500;
    &:hover{
        background:linear-gradient(32deg, #FD661C 22%, #FC4C00 87%);
        color: #fff;
    }
}
button.primary_btn {
    border: none; /* Reset button border */
  }
.secondary_btn {
    font-size: 20px;
    height: 50px;
    min-width: 164px;
    border-radius: 50px;
    text-align: center;
    border: 3px solid #fd661c; 
    line-height: 26px;
    display: inline-block;
    padding: 10px;
    transition: all 0.5s;
    color:$orange;
    font-weight: 500;
    background: transparent;
    &:hover{
        background: #FD661C;
        color: #fff;
    }
}


.tertiaryButton{
    background:$tertiaryButton;
    font-size: 20px;
    height: 50px;
    min-width: 250px;
    line-height: 40px;
    display: inline-block;
    border-radius: 50px;
    padding: 5px;
    transition: all 0.5s;
    color:$white;
    font-weight: 500;
    text-align: center;
    &:hover{
        background: linear-gradient(22deg, #2343CC 22%, #1A2F89 93%);
        box-shadow: 0px 9px 8px rgb(11 28 97 / 35%);    
    }
}

.orange_gredient_color_text{
    background: -webkit-linear-gradient(rgba(253,102,28,1), rgba(254,151,47,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    padding: 40px 0;
    .logo{
        img{
            cursor: pointer;
            width: 100%;
            max-width: 240px;
        }
    }
    .row{
        position: relative;
        .logo{
            img{
                cursor: pointer;
            }
        }
        nav{
            .menu{
                display: flex;
                li{
                    margin: 0 9px;
                    a{
                        color: #ffffff;
                        font-size: 18px;
                        padding: 0 10px;
                        font-weight: 400;
                        cursor: pointer;
                        transition: all 0.5s;
                        position: relative;
                        &:hover{
                            color: $menuHover;
                        }
                        
                    }
                    &.active {
                        a {
                            &:after {
                                content: "";
                                width: 100%;
                                height: 2px;
                                position: absolute;
                                bottom: -5px;
                                background: #FE972F;
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }   
            }
        }
        .menuButton{
            li{
                margin-left:20px;
            }
        }
        .d-none-mobile{
            padding-left: 0px;
        }
    }

}



.heroBanner{
    padding: 130px 0 120px;
    .bannerContent{
        h1{
            font-size: 120px;
            color: $white;
            line-height: 107px;
            font-weight: 700;
        }
        p{
            color: $white;
            padding-top: 35px;
            width: 100%;
            max-width: 460px;
        }
    }
}


.logoSlider{
    margin-top: -62px;
    .logoSliderWrapper {
        background: $white;
        border-radius: 14px;
        padding: 40px;
        position: relative;
        z-index: 1;
        box-shadow: 0px 14px 40.7px rgb(11 28 97 / 18%);
        >div{
            padding: 10px;
            text-align: center;
        }
    }
}



.tradeSettled{
    padding: 110px 0 80px;
    .bannerContent{
        h2{
            color: $dark_blue;
            width: 100%;
            max-width: 530px;
        }
        .guarnteed_image{
            margin-bottom: -13px;
            padding-top: 20px;
            img{
                position: relative;
                left: -29px;
            }
        }
        p{
            width: 100%;
            max-width: 520px;
        }
        .viewMarketBtn{
            padding-top: 13px;
            a{
                width: 250px; 
            }
        }
    }
    .tradeForm {
        box-shadow: 0px 14px 47px rgba(11, 28, 97, 0.18);
        background: $white;
        border-radius: 23px;
        padding: 45px 35px;
        h4 {
            font-size: 31px;
            color: $dark_blue;
            font-weight: 600;
            text-align: center;
        }
        p{
            text-align: center;
            padding-bottom: 24px;
        }
        .form-field{
            position: relative;
            margin-bottom: 20px;
            input{
                border: 1px solid #1A2F89;
                font-size: 20px;
                height: 60px;
            }
            select{
                border: 1px solid #1A2F89;
                font-size: 20px;
                height: 60px;
            }
            .secondary_btn {
                min-width: 97%;
                display: block;
                margin-left: auto;
            }
            .primary_btn {
                min-width: 97%;
                border: none;
                
            }
            .orSeprate{
                position: absolute;
                top: 15px;
                right: -23px;
            }
        }
        .moreSpace{
            margin-top: 25px;
        }
    }
}


.customizedAnalysis{
    background-image: $darkBlueGradient;
    padding: 90px 0;
    .customizedAnalysisContent{
        width: 100%;
        max-width: 600px;
        h2{
            color: $white;
        }
        p{
            color: $white;
            padding-top: 15px;
            margin:0px;
        }
    }
}


.onlyOtcDesk{
    padding: 70px 0;
    .onlyOtcDeskHeading {
        width: 100%;
        max-width: 690px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 40px;
        h2{
            color:$dark_blue;
        }
        p{
            padding-top: 40px;
        }
    }
    .row{
        margin:0 -40px;
        .col-md-4{
            padding: 0 40px;   
             
        }
    }
    .otcDeskBox{
        box-shadow: 0px 14px 47px rgb(11 28 97 / 18%);
        border-radius: 20px;
        margin-bottom: 58px;
        transition: all 0.5s;
        border: 1px solid transparent;
        .otcDeskBoxTop{
            img{
                margin: 0 auto;
            }
            text-align: center;
            padding: 25px;
            background: hsla(229, 71%, 47%, 1);
            background: radial-gradient(circle, hsla(229, 71%, 47%, 1) 0%, hsla(228, 80%, 21%, 1) 100%);
            background: -moz-radial-gradient(circle, hsla(229, 71%, 47%, 1) 0%, hsla(228, 80%, 21%, 1) 100%);
            background: -webkit-radial-gradient(circle, hsla(229, 71%, 47%, 1) 0%, hsla(228, 80%, 21%, 1) 100%);
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 130px;
            border-bottom-right-radius: 130px;
            h3{
                font-size: 35px;
                font-weight: 700;
                color: $white;
                margin: 15px 0 0 0;
                min-height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .otcDeskBoxContent{
            padding: 28px 45px;
            text-align: center;
            min-height: 216px;
            text-align: center;
            p{
                margin: 0;
            }
        }
        &:hover{
            border: 1px solid #2343CC;
            box-shadow: 0px 14px 40.7px rgb(35 67 204 / 40%);        
        }
    }   
    .otcDeskBtn{
        margin-top: 10px;
        text-align: center;
        a{
            min-width: 315px;
        }
    }
}


.moneyAccess{
    background-image: $darkBlueGradient;
    padding-top: 70px;
    h2{
        text-align: center;
        color:$white;
        max-width: 800px;
        width: 100%;
        margin: 0 auto 70px auto;
    }
}


.experienced-section {
    padding-top: 100px;
    background: rgb(227 229 233 / 30%);
    padding: 35px 0;
    .row {
        align-items: center;
    }
    .col-md-6 {
        h2 {
            color: #0B1C61;
            text-align: left;
            font-size: 42px;
            font-weight: 700;
        }
        a {
            min-width: 315px;
        }
    }

}


.invest-section {
    padding-top:70px;
    padding-bottom:70px;
    position: relative;
    .onlyOtcDeskHeading{
        text-align: center;
        h2{
            color: $dark_blue;
            width: 100%;
            max-width: 500px;
            margin: 0 auto 30px auto;
        }
    }
    .line {
        height: 410px;
        background-repeat: no-repeat;
        margin-top: -140px;
    }
    .row {
        
   
    .col-md-4 {
        .invest-main {
            h3 {
                color: #FD661C;
                font-weight: 700;
                font-size: 35px;
                padding-top: 8px;
            }
            p {
                color: #0B1C61;
    font-size: 20px;
    width: 100%;
    max-width: 282px;
    margin: 0 auto;
    padding-top: 20px;
            }
            .investArrowIcon{
                margin-bottom: 50px;
            }
        }
        &:nth-child(2){
            padding-top:90px;
            .investArrowIcon{
                margin-top: 25px;
            }
        }  
        &:nth-child(3){
            .investArrowIcon{
                margin-top: -19px;
            }
        }   
    }
   
  }
  &:before{
    content: "";
    background-image: url('../images/line_effect.svg');
    width: 100%;
    position: absolute;
    height: 390px;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    top: 151px;
    background-size: cover;
    z-index: -1;
    background-position: bottom center;
  }
}



footer {
    .container {
        .row {
            padding-bottom: 20px;
        }
    }
    background: $darkBlueGradient;
    padding: 80px 0 50px;
    
    .col-md-3{
       .logo{
            padding-bottom: 50px;
            img{
                width: 100%;
                max-width: 240px;
            }
       } 
       .social-icon {
        img {
          padding: 0 10px;
        }
       }
    }
    .col-md-2 , .col-md-3{
        h3 {
            font-size: 23px;
            font-weight: 700;
            color: #FFFFFF;
            text-align: left;
            padding-bottom: 20px;
        }
        .nav {
            .menu {
                li {
                    list-style: none;
                    text-align: left;
                    padding-bottom: 6px;
                    a {
                        text-decoration: none;
                        color: #fff;
                        cursor: pointer;
                        transition: all 0.5s;
                        &:hover{
                            color: #7B91EB;
                        }
                    }
                }
            }
        }
     }
     .d-block-mobile{
        display: none;  
     }
     .footer-text {
        padding-top: 40px;
        border-top: 1px solid #7B91EB;
        p {
            color: #fff;
            width: 100%;
            max-width: 1200px;
            text-align: center;
            font-size: 14px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
     }
} 




.signUpMain{
    background-image: url('../images/login-bg.webp');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 130px 0;
    .signUpBox{
        background: $white;
        padding: 30px;
        border-radius: 32px;
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        .signUpBoxHeader{
            text-align: center;
            h3{
                color: $dark_blue;
                font-size: 38px;
                font-weight: 600;
            }
            p{
                font-size: 17px;
                margin: 13px 0 30px 0px;
            }
        }
        .form-group{
            margin-bottom: 18px;
            .form-control{
                height: 50px;
                font-size: 18px;
                background-color: #EBF0FF;
                border: 1px solid #7B91EB;
                border-radius: 10px;
            }
        }
        .primary_btn{
            min-width: 100%;
            margin-top: 17px;
            border: none;
        }
        .buttonBtmText {
            p{
                color: #7A7B7E;
                font-size: 15px;
                margin: 0;
                padding: 18px 0;
                border-bottom: 1px solid #cecece;
                a{
                    color: #FC4C00;
                    font-weight: 600;
                }
                &:last-child{
                    border: none;
                    padding-bottom: 0px;
                }
            }
        }
        .forgotPsBtns{
            margin-top: 20px;
            p{
                border: none;
            }
            .backToLoginBtn{
                a{
                    color: #7A7B7E;
                    font-weight: 400;
                    background-image: url('../images/back-btn.svg');
                    padding-left: 28px;
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: 0 center;
                }
            }
        }
    }
}


.loginForm{
    .signUpForm{
        form{
            .remberForgot{
                margin-bottom: 11px;
                .form-check{
                    padding-left: 0px;
                    input{
                        display: none;
                    }
                    input:checked + label:before {
                        background-color: #1A2F89;
                    }
                    input:checked + label:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: 10px;
                        width: 6px;
                        height: 14px;
                        border: solid #ffffff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                    label{
                        color: #7A7B7E;
                        font-size: 15px;
                        position: relative;
                        cursor: pointer;
                        &::before{
                            content:'';
                            -webkit-appearance: none;
                            background-color: transparent;
                            border: 2px solid #1A2F89;
                            padding: 10px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            margin-right: 8px;
                            height: 24px;
                            width: 24px;
                            border-radius: 5px;
                        }
                    }
                    
                }
                .remberForgottext{
                    a{
                        color: #FC4C00;
                        font-weight: 600;
                        font-size: 15px;
                    }
                }
            }

        }
    }

}

.otpWrapper{

    .signUpBoxHeader{
        p{
            width: 100%;
            max-width: 330px;
            margin-left: auto;
            margin-right: auto;
        }
    } 
    .otpCode{
        margin-bottom: 0px !important;
        padding: 10px 0 23px 0;
        display: flex;
        column-gap: 25px;
        width: 100%;
        max-width: 328px;
        margin: 0 auto;
        justify-content: center;
        input{
            height: 62px !important;
            text-align: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
    }
    .primary_btn{
        width: 100%;
        max-width: 328px !important;
        min-width: initial !important;
        display: block;
        text-align: center;
        margin: auto;
    }



}

/*========= Personal Page Css =========*/

.personal_Banner{
    padding: 130px 0 80px;
    .bannerContent {
        max-width: 800px;
        margin:0 auto;
        text-align: center;
        h1{
            line-height: 138px;
        }
        p{
            max-width: 510px;
            margin: 0 auto;
        }
    }
}

.key-reason-section{
    background-color: $gray-bg;
    padding: 80px 0;
    .onlyOtcDeskHeading{
        h2{
            width: 100%;
            max-width: 640px;
            text-align: center;
            margin: 0 auto;
            color:$dark_blue;
            line-height: 74px;
            margin-bottom: 30px;
        }
        p{
            text-align: center;
            width: 100%;
            max-width: 1190px;
            margin: 0 auto;
        }
    }
    .row{
        margin-top: 60px;
        .col-md-6{
            margin-bottom:35px ;
            .key_reason-boxes {
                background: #fff;
                position: relative;
                padding-left: 127px;
                border-radius: 17px;
                padding-top: 25px;
                padding-right: 25px;
                padding-bottom: 25px;
                height: 100%;
                box-shadow: 0px 14px 40.7px rgb(11 28 97 / 15%);
                img {
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    left: 28px;
                }
                h4 {
                    font-weight: 700;
                    color: $dark_blue;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 19px;
                    margin-bottom: 4px;
                    width: 100%;
                    max-width: 460px;
                }
            }
        }   
    }
    .tertiaryButton{
        margin-top: 20px;
    }
}


.personal_exp-btn{
    background: $white;
    h4{
        font-size: 29px;
        color: #0B1C61;
        line-height: 38px;
        font-weight: 700;
    }
    .col-md-4{
        a{
            min-width: 315px;
        }
    }
}


.key-p-slider{
    margin-top: 40px;
    margin-left: -20px;
    margin-right: -20px;
    .slick-slide {
        padding: 0 20px;
        .key_reason-boxes{
            margin-bottom: 30px;
            h4{
                font-size: 21px;
                font-weight: 700;
                color:$dark_blue;
            }
        }

    }
}



.unleashing_crypto-h{
    .onlyOtcDeskHeading{
        padding-bottom: 0px;
        h2{
            max-width: 790px;
            margin-bottom: 0px;
        }
    }
}

.optimised_trading{
    padding: 70px 0;
    .onlyOtcDeskHeading{
        padding-bottom: 40px;
        h2{
            color:$dark_blue;
            width: 100%;
            max-width: 630px;
            margin: 0 auto 21px auto;
        }
        p{
            width: 100%;
            max-width: 1250px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .optimize_business{
        .optimised_blue{

            p{
                margin-bottom: 20px;
                max-width: 580px;
            }
        }
    }
    .optimised_blue_p_business{
        .optimised_blue{
            min-height: 245px;
        }
    }
    .optimised_blue{
        background:$dark_blue;
        padding: 49px 35px 37px 35px;
        border-radius: 10px;
        margin-bottom: 25px;
        h4{
            font-size: 30px;
            color: $white;
            font-weight: 600;
            padding-bottom: 4px;
        }
        p{
            color: $white;
            margin-bottom: 30px;    
        }
        img{
            width: 100%;
            max-width: 403px;
        }
    }
}

.key_features_sec{
    .onlyOtcDeskHeading{
        h2{
            max-width: 1010px;
        }
    } 
    .optimised_blue{
        img{
            margin-top: 25px;
        }
    }  
    .mt-25_opt{
        margin-top: 20px;
    }
    .opt_box_security {
        min-height: 248px;
        p{
            padding-right: 20px;
        }
    } 
}



.how-it-works{
    padding: 80px 0;
    .how-it-bannerContent{
        h2{
            color: #fe5d0f;
            margin-bottom: 20px;
        }
        p{
            color:#fff;
        }
    }
    .how-it-image{
        img{
            width: 100%;
            max-width: 580px;
            
        }
    }
    .od-1-mb{
        text-align: right;
    }
    .mb-30-how {
        margin-bottom: 60px;
    }

}


.secure-crypto-lbs{
    background-color: $gray-bg;
    .onlyOtcDeskHeading{
        h2{
            max-width: 720px;
        }
    }
    .optimised_blue{
        padding: 29px 35px 37px 35px;
        h4{
            width: 100%;
            max-width: 501px;
        }
        p{
            margin-bottom: 0px;
        }
    }
    .secure_img{
        img{
            max-width: 590px;
            margin: 0 auto;
            display: block;
        }
    }
}


.advanced_transition{
    .onlyOtcDeskHeading{
        h2{
            max-width: 800px;
            color:$white;
        }
    }
    .mt-30{
        margin-top: 20px;
        .primary_btn{
            min-width: 204px;
        }
    }
}


.herobanner_two{
    .bannerContent{
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        h1{
            line-height: 137px;
        }
        p{
            margin: 0 auto;
            max-width: 500px;
        }
    }
}


.ensure_security{
    .onlyOtcDeskHeading{
        padding-bottom: 0px;
        h2{
            max-width: 1070px;
            margin: 0 auto 26px auto;
            line-height: 80px;
        }
    }
}

.what-twofa{
    background-color:$gray-bg;
    padding: 70px 0;
    .customizedAnalysisContent{
        h2{
            color: $dark_blue;
        }
        p{
            color: $black;
            max-width: 670px;
            padding-top: 17px;
        }
    }
}


.how-two-work{
    background-color: $white;
    .onlyOtcDeskHeading{
        h2{
            max-width: 500px;
        }
    }
}


.benifits_work2fa{
    .onlyOtcDeskHeading{
        h2{
            max-width: 340px;
        }
    }
}

.our_commint-sec{
    .onlyOtcDeskHeading{
        h2{
            max-width: 590px;
        }
    }
}


.howsetup_fa{
    background-color: $gray-bg;
    .onlyOtcDeskHeading{
        max-width: 100%;
     h2{
        text-align: left;
     }
    }
    .row{
        margin: 0 -15px;
        .otcDeskBox{
            background-color: $white;
            .otcDeskBoxTop{
                background: radial-gradient(128.39% 128.39% at 0% 50%, #1A2F89 22.47%, #2343CC 92.76%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                h3{
                    font-size: 60px;
                    min-height: 50px;
    
                }
            }
            .otcDeskBoxContent{
                padding: 28px 25px;
                min-height: 394px;
                h4{
                    font-size: 24px;
                    font-weight: 700;     
                    color: $dark_blue;
                    margin-top: 26px;
                    line-height: 32px;
                    margin-bottom: 17px;
                    max-width: 230px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    .col-md-3{
        &:first-child{
            position: relative;
            top: 40px;
        }
        &:nth-child(3){
            position: relative;
            top: 40px;
        }
    }
}


.aboutBanner{
    flex-wrap: wrap;
    padding-top: 150px;
    padding-bottom: 100px;
    height: auto !important;
    .bannerContent{
        padding: 40px 0 50px 0;
        h1{
            font-size: 64px;
            line-height: 87px;
            font-weight: 700;
            width: 100%;
            max-width: 860px;
            text-align: left;
            margin: 0 auto;
        }
        p{
            padding-top: 25px;
        }
        .bannerContentBtn{
            margin-top: 28px;
            a{
                min-width: 200px;
            }
        }
    }
    .aboutBanner_img{
        text-align: center;
        flex: 0 0 100%;
        max-width: 100%;
        img{
            width: 100%;
            max-width: 1350px;
            margin: 0 auto;
        }
    }
}

.mapAboutFooter{
    position: relative;
    #map-canvas{
        border:none;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        margin-bottom: -7px;
    }
    .mapAddress{
        background: $dark_blue;
        filter: grayscale(0%) !important;
        width: 100%;
        max-width: 299px;
        border-radius: 14px;
        text-align: center;
        position: absolute;
        padding: 18px;
        height:170px;
        img{ 
            width: 126px;
        }
        h4{
            font-size: 21px;
            color: #fff;
            font-weight: 600;
            margin: 20px 0;
        }
        p{
            font-size: 16px;
            color: #fff;
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
        }
        &:after{
            content: "";
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 13px solid #0B1C61;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -12px;
            margin: 0 auto;
        }
    }
}

.streamlined_sec{
    background-color: $white;
    padding-bottom: 60px;
    .row{
        margin-top: 0px;
        .Streamlined_image{
            img{
                max-width: 610px;
            }
        }
        .onlyOtcDeskHeading{
            h2{
                width: 100%;
                max-width: 100%;
                text-align: left;
            }
            p{
                width: 100%;
                max-width: 100%;
                text-align: left;
            }
        }
    }
    
}


.automatedStream{
    .how-it-bannerContent{
        h2{
            color: $white;
            span{
                color: #FD661C;
            }
        }
    }
}

.serviveAbout{
    .customizedAnalysisContent{
        p{
            color: $dark_blue;
        }
    }
}


.billing_intro{
    .onlyOtcDeskHeading{
        padding-bottom: 0px;
        h2{
            max-width: 730px;
        }
    }
}

.billingBenefits{
    background-color: $gray-bg;
    .onlyOtcDeskHeading {
        h2{
            max-width: 770px;
        }
    }
}

.unlockBusinesss{
    .onlyOtcDeskHeading{
        h2{
            color: $white;
            max-width: 1050px;
        }
    }
    .primary_btn{
        min-width: 204px;
    }
}


.benifitsPosSec{
    .onlyOtcDeskHeading{
        h2{
            max-width: 840px;
        }
    }
}

.businessPosBanner{
    padding: 170px 0 80px;
    position: relative;
    .bannerContent{
        text-align: left;
    }
    .businessImgPos{
        position: absolute;
        right: 0px;
        bottom: 0px;
        img{
            width: 100%;
            max-width: 509px !important;
        }
    }
}


.feesBanner{
    .bannerContent{
        max-width: 1165px;
        margin: 0 auto;
        h1{
            font-size: 116px;
            line-height: 127px;
        }
        p{
            max-width: 100%;
        }
    }
}


.keyFeesWhite{
    padding: 80px 0;
    background: $white;
    .onlyOtcDeskHeading{
        margin-bottom: 50px;
        h2{
            max-width: 550px;
            text-align: center;
            margin: 0 auto;
            color: $dark_blue;
            line-height: 74px;
            margin-bottom: 30px;
        }
        p{
            text-align: center;
            width: 100%;
            max-width: 1190px;
            margin: 0 auto;
        }
    }
}


/*======= Table Tabs =======*/
.tableWrapper{
    #accordionExample{
        .accordion-item{
            background: #EBF0FF;
            .accordion-header{
                button{
                    font-weight: 600;
                    font-size: 16px;
                    background: transparent;
                    &:after{
                        content: "";
                        background-image: url('../images/faq_ion.svg');
                        background-size: 16px;
                        background-position: center;
                    }
                }
            }
            .accordion-body{
                padding: 0px;
                .tabsLeftBg{
                    padding: 20px;
                    h4 {
                        font-size: 16px;
                        color: #fff;
                        line-height: 26px;
                        font-weight: 500;
                    }
                    p{
                        color: #C2CDF9;
                        font-size: 15px;
                        margin: 23px 0;
                    }
                }
                table{
                    background: #F5F8FF;
                    border: 1px solid #C6C8CF;
                    thead{
                        tr{
                            th{
                                border: none;
                                background: transparent;
                                font-size: 10px;
                                padding-top: 16px;
                                padding-bottom: 16px;
                                &:nth-child(2){
                                    width: 29%;
                                }
                                &:nth-child(3){
                                    width: 29%;
                                }
                            }
                        }
                    }
                    tbody{
                        tr{

                            td{
                                background: transparent;
                                font-size: 10px;
                                color: #616161;
                            }
                        }
                    }
                }
            }
        }
    }


    .nav{
        background:#EBF0FF;
        border: 1px solid #C2CDF9;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px 20px;
        button{
            padding: 8px 16px;
            color: $light_blue;
            border: none;
            &.active{
                background-color: transparent;
                font-weight: 600;
                color: $dark_blue;
            }
        }
    }
    .tab-content{
        .tab-pane{
            .col-md-3{
                padding-right: 0px;
                .tabsLeftBg{
                    height: 100%;
                    padding: 40px;
                    h4{
                        color: $white;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        padding-bottom: 32px;
                    }
                    p{
                        color: #F5F8FF;
                        line-height: 22px;
                        font-size: 16px;
                        padding-bottom: 20px;
                    }
                }
            }
            .col-md-9{
                padding-left: 0px;
                table{
                    height: 100%;
                    border:1px solid #C2CDF9;
                    background-color: #F5F8FF;
                    margin: 0;
                    tr{
                        th{
                            border: none;
                            padding: 13px 30px;
                            background-color: transparent;
                            color: $dark_blue;
                            font-weight: 600;
                        }
                    }
                    tr{
                        td{
                            background-color: transparent;
                            padding: 13px 30px;
                            color: #616161;
                            font-size: 16px;
                        }
                    }
                }
            }

        }
    }
}

/*======= Table Tabs =======*/


/*======= elevateCrypto Table =======*/
.elevateCrypto{
    padding: 60px 0px;
    .onlyOtcDeskHeading{
        margin-bottom: 50px;
        h2{
            color: $dark_blue;
            width: 100%;
            max-width: 611px;
            margin: 0 auto;
            text-align: center;
        }  
    }
    table{
        background-color: $gray-bg;
        border-radius: 10px;
        border-collapse: collapse;
        thead{
            th{
                padding: 30px 18px;
                background-color: transparent;
                &:first-child{
                    text-align: left;
                }
                &:nth-child(2){
                    background-image: url('../images/table-cell-header.png');
                    background-size: cover;
                    background-position: center;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 16px;
                    color: #616161;
                    padding: 15px 18px;
                    background-color: transparent;
                    &:first-child{
                        text-align: left;
                    }
                    &:nth-child(2){
                        background-color: $white;
                        border-left:1px solid #1A2F89;
                        border-right:1px solid #1A2F89;
                    }
                }
                &:last-child{
                    td{
                        &:nth-child(2){
                            border-bottom:1px solid #1A2F89;
                        }
                    }
                }

            }

        }
    }
}
/*======= elevateCrypto Table =======*/


.priceFees{
    background: $darkBlueGradient;
    padding: 70px 0 150px 0;
    .onlyOtcDeskHeading{
        text-align: center;
        margin-bottom: 85px;
        h2{
            color:$white;
            width: 100%;
            max-width: 740px;
            margin: 0 auto 30px auto;
        }
        p{
            width: 100%;
            max-width: 1190px;
            margin: 0 auto;
            color: $white;
        }
    }
    .row{
        .col-md-4{
            padding: 0px;
            .priceBoxeInner{
                background-color: $gray-bg;
                padding: 30px;
                border-radius: 20px;
                padding-bottom: 100px;
                position: relative;
                .priceBoxetop{
                    h5{
                        font-size: 24px;
                        color: $dark_blue;
                        font-weight: 700;
                    }
                    span{
                        font-size: 16px;
                        color: $dark_blue;
                    }
                    p{
                        font-size: 20px;
                        color: $black;
                        
                    }
                    h3{
                        font-size: 128px;
                        line-height: 132px;
                        color: $dark_blue;
                        font-weight: 700;
                        padding-top: 45px;
                        span{
                            font-size: 32px;
                        }
                    }
                }
                .priceBoxeMiddle{
                    background: $white;
                    color: $dark_blue;
                    font-weight: 700;
                    font-size: 16px;
                    padding: 20px 40px;
                    margin-top: 44px;
                    margin-bottom: 29px;
                }
                p{
                    font-size: 16px;
                    color: #616161;
                    
                }
            }
            .priceBtnsF{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 40px;
                z-index: 99;
            }
            &:nth-child(1){
               margin-right: -16px; 
            }
            &:nth-child(2){
                .priceBoxeInner{
                    background-color: $white;
                    height: 115%;
                    position: relative;
                    top: -7%;
                    z-index: 9;
                    .priceBoxeMiddle{
                        background: $gray-bg;
                    }
                }
            }
            &:nth-child(3){
                margin-left: -16px; 
            }
        }
    }
}




.feesFaq{
    background-color: $gray-bg;
    padding: 70px 0;
    .onlyOtcDeskHeading{
        margin-bottom: 50px;
        h2{
            color: $dark_blue;
            text-align: center;
        }
    }
    #accordionExample{
        width: 100%;
        max-width: 934px;
        margin: 0 auto;
        .accordion-item{
            box-shadow: 0px 14px 40px rgb(11 28 97 / 14%);
            margin-bottom: 20px;
            background-color: $white;
            border-radius: 10px;
            .accordion-header{
                background-color: transparent;
                button{
                    font-size: 22px;
                    padding: 26px 20px;
                    position: relative;
                    color: $dark_blue;
                    font-weight: 600;
                    box-shadow: none;
                    background-color: transparent;
                    &:before{
                        content: "";
                        display: inline-block;
                        height: 45px;
                        width: 45px;
                        box-shadow: 0px 14px 40px rgb(11 28 97 / 14%);
                        background-color: $white;
                        position: absolute;
                        right: 20px;
                        z-index: 0;
                        border-radius: 50%;
                    }
                    &:after{
                        z-index: 1;
                        background-image: url('../images/faq_ion.svg');
                        position: relative;
                        left: -13px;
                        font-weight: bold;
                        bottom: -6px;
                    }
                }
                button[aria-expanded="true"]{
                    &:after{
                        bottom: 4px;  
                    }
                }
            }
        }
        .accordion-body {
            font-size: 20px;
            color: $light_blue;
        }
    }
}

 


.emailverification{
    padding: 41px !important;
    max-width: 578px !important;
    .signUpBoxHeader{
        h3{
            margin-top: 20px;
        }
    }
    .signUpForm{
        padding-bottom: 30px;
        p{
            text-align: center;
            font-size: 17px;
            margin: 20px 0;
            a{
                font-weight: 600;
                color: #FC4C00;
            }
        }
    }
    .buttonBtmText{
        p{
            border-top: 1px solid #cecece !important;
        }
    }
}



/*======= KYC ==========*/

.signUpMain{
    .kycWrapperForm{
        padding: 40px 30px;
        max-width: 986px;
        .signUpBoxHeader{
            p{
                max-width: 630px;
                margin: 0 auto 10px auto;
            }
        }
        .signUpForm{
            #msform{
                padding-top: 18px;
                #progressbar{
                    width: 100%;
                    max-width: 770px;
                    margin: 0 auto;
                    display: flex;
                    padding-bottom: 50px;
                    justify-content: space-between;
                    li{
                        position: relative;
                        width: 25%;
                        strong{
                            width: 100%;
                            max-width: 78px;
                            display: block;
                            text-align: center;
                            color: #7A7B7E;
                            line-height: 24px;
                            font-weight: 400;
                            margin-top: 10px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        span{
                            height: 33px;
                            width: 33px;
                            background-color: $white;
                            border: 5px solid #C6C8CF;
                            display: block;
                            border-radius: 50%;
                            margin: 0 auto;
                            position: relative;
                            z-index: 9;
                            
                        }
                        &.active{
                            strong{
                                color: $dark_blue;
                                font-weight: 700;
                            }
                            span{
                                border-color: transparent;
                                box-shadow: 0px 4px 40.7px rgb(35 67 204 / 40%);
                                &:after{
                                    content: "";
                                    height: 17px;
                                    width: 17px;
                                    position: absolute;
                                    background-color: #2343CC;
                                    top: 3px;
                                    left: 3px;
                                    border-radius: 50%;
                                }   
                            }
                        }
                        &.complete{
                            span{
                                background-color: #2343CC;
                                box-shadow: none;
                                border: transparent;
                                &::before{
                                    content: "";
                                    width: 100%;
                                    height: 100%;
                                    background-image: url('../images/check-white.svg');
                                    position: absolute;
                                    top: 0;
                                    background-repeat: no-repeat;
                                    left: 0;
                                    background-position: center;
                                    background-size: 18px;
                                }
                            }
                            strong{
                                color: #0B1C61;
                            }
                            &:after{
                                background-color: #2343CC;
                            }
                        }
                        &:after{
                            content: "";
                            width: 100%;
                            height: 5px;
                            background-color: #DBDCE0;
                            position: absolute;
                            right: -50%;
                            top: 15px;
                            z-index: 0;
                        }
                        &:last-child{
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
                fieldset{
                    .row{
                        .form-group{
                            margin-bottom: 27px;
                            label{
                                margin-bottom: 10px;
                                color: #0B1C61;
                            }
                        }
                    }
                    .formStepFooter{
                        border-top: 1px solid #DBDCE0;
                        padding-top: 14px;
                        margin-top: 14px;
                        text-align: right;
                        button{
                            min-width: 170px;
                            margin-left: 20px;
                        }
                    }
                    .formCustomRadio{
                        padding: 0 20px;
                        margin-bottom: 33px;
                        p{
                            font-size: 16px;
                            color: #0B1C61;
                        }
                    }
                    .formCheckB{
                        padding: 0 20px;
                        p{
                            font-size: 16px;
                            color: #0B1C61;
                        }
                    }
                }
                fieldset{
                    .successCongImg{
                        width: 100%;
                        max-width: 640px;
                        margin: 0 auto;
                        h3{
                            font-size: 45px;
                            color:$dark_blue;
                            font-weight: 600;
                            margin: 24px 0 15px 0;
                        }
                    }
                }
            }
        }
    }
}


/*========= KYC ==========*/





