@media (min-width:2400px){
    div#root {
        width: 100%;
        margin: 0 auto;
        box-shadow: 0px 0px 9px #cccccc8c;
    }   
}


@media (min-width:1921px){
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1600px;
    }
}


@media (max-width:1200px){
    .container-lg, .container-md, .container-sm, .container {
        max-width: 1180px;
    }   
}


@media (min-width:1025px) and (max-width:1200px){
    .invest-section {
        .onlyOtcDeskHeading{
            text-align: center;
            h2{
            }
        }
        .row {
            
        .col-md-4 {
            .invest-main {
    
                .investArrowIcon{
                    margin-bottom: 50px;
                    img{
                        transform: rotate(-4deg);
                        position: relative;
                        top: -5px;
                    }
                }
            }
            &:nth-child(2){
                padding-top: 55px;
                .investArrowIcon{
                    margin-top: 25px;
                    img{
                        transform: none;
                        position: relative;
                        top: 0px;
                    }
                }
            }  
            &:nth-child(3){
                .investArrowIcon{
                    margin-top: -18px;
                    img{
                        transform: rotate(4deg);
                        position: relative;
                        top: 2px;
                    }
                }
            }   
        }
       
      }
      &:before{
        content: "";
        top: 163px;
        height: 338px;
      }
    }

}


@media (max-width:1024px){
    .container-lg, .container-md, .container-sm, .container {
        max-width: 1020px;
    } 
    h2, .h2 {
        font-size: 49px;
    }
    .heroBanner{
        padding: 150px 0 130px;
        height: auto !important;
        .bannerContent{
            h1{
                font-size: 90px;
                line-height: 87px;
            }
        }
    }
    .tradeSettled{
        .bannerContent{
            .guarnteed_image{
                margin-bottom: -23px;
                padding-top: 6px;
                display: block;
                text-align: center;
                img{
                    width: 100%;
                }
            }
        }
        .tradeForm {
            padding: 35px 25px;
            .form-field{
                input{
                    font-size: 18px;
                    height: 48px;
                    border-radius: 12px;
                }
                select{
                    font-size: 18px;
                    height: 48px;
                    border-radius: 12px;
                }
                .secondary_btn {
                    min-width: 197px;
                    margin: 0 auto;
                }
                .primary_btn {
                    min-width: 197px;
                    margin: 0 auto;
                }
                .orSeprate{
                    position: initial;
                    display: block;
                    text-align: center;
                    color: #0B1C61;
                    font-size: 20px;
                    margin-top: 10px;
                }
            }
            .moreSpace{
                margin-top: 9px;
                margin-bottom: 0;
                text-align: center;
            }
        .tabletFullWidth{
            width: 100%;
        }
        }
    }

    .onlyOtcDesk{
        .onlyOtcDeskHeading {
            h2{
              img{
                width: 100%;
                max-width: 140px;
                margin-top: 10px;
              }
    
            }
        }

        .row{
            margin: 0 -10px;
            .col-md-4{
                padding: 0 20px;
                width: 50%;
                .otcDeskBox{
                    margin-bottom: 37px;
                    .otcDeskBoxContent{
                        padding: 28px 30px;
                    }
                }
            }
        }
        .slick-slide{
            .otcDeskBox{
                margin-bottom: 0px;
                box-shadow: none;
                .otcDeskBoxTop{
                    border-bottom-left-radius: 80px;
                    border-bottom-right-radius: 80px;
                    img{
                        margin: 0 auto;
                        width: 63px;
                    }
                    h3{
                        font-size: 29px;
                        min-height: 80px;
                    }
                }
             
            }
        }
        .otcDeskBtn{
            margin-top: 14px;
            text-align: center;
            a{
                min-width: 248px;
            }
        }
    
    }
    
    .invest-section {
        .onlyOtcDeskHeading{
            text-align: center;
            h2{
            }
        }
        .line {
            height: 410px;
            background-repeat: no-repeat;
            margin-top: -140px;
        }
        .row {
            
        .col-md-4 {
            .invest-main {
                h3 {
                    color: #FD661C;
                    font-weight: 700;
                    font-size: 30px;
                    padding-top: 8px;
                }
                p {
                    
                    padding-top: 20px;
                }
                .investArrowIcon{
                    margin-bottom: 50px;
                    img{
                        // transform: rotate(-4deg);
                        // position: relative;
                        // top: -5px;
                    }
                }
            }
            &:nth-child(2){
                padding-top: 55px;
                .investArrowIcon{
                    margin-top: 25px;
                    img{
                        // transform: none;
                        // position: relative;
                        // top: 0px;
                    }
                }
            }  
            &:nth-child(3){
                .investArrowIcon{
                    margin-top: 1px;
                    img{
                        transform: rotate(8deg);
                        position: relative;
                        top: 2px;
                    }
                }
            }   
        }
       
      }
      &:before{
        content: "";
        top: 156px;
        height: 308px;
      }
    }

    .personal_exp-btn  {
        .col-md-4 {
            a{
                min-width: 235px;
            }
        }
    }

}




@media (max-width:991px){

    .d-none-mobile{
        display: none;
    } 
    .header{
        padding: 38px 0;
    }
    .logo {
        img{
            width: 100%;
            max-width: 190px !important;
        }
    }  
    .menuIcon {
        position: absolute;
        right: 0;
        width: 50px;
        padding: 0;
        top: -4px;
        display: inline-block;
        height: 40px;
        button{
            background: transparent;
            border: none;
            width: 100%;
        }
    }
    .MobileResponsive {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 29px 20px 15px 20px;
        background: hsl(229, 71%, 47%);
        background: radial-gradient(circle, hsl(229, 71%, 47%) 0%, hsl(228, 80%, 21%) 100%);
        background: -webkit-radial-gradient(circle, hsl(229, 71%, 47%) 0%, hsl(228, 80%, 21%) 100%);
        transition: all 0.5s;
        transform: translateX(100%);
        .row{
            .col-2{
                .closeIcon{
                    background: transparent;
                    border: none;  
                }
            }
        }
        .menu{
            li{
                margin: 21px 0;
                a{
                    color: #ffffff;
                    font-size: 19px;
                    padding: 0 10px;
                    font-weight: 400;
                    transition: all 0.5s;
                    position: relative;
                    &:hover{
                        color: #FE972F;
                    }
                }
                &.active {
                    a {
                        &:after {
                            content: "";
                            width: 100%;
                            height: 2px;
                            position: absolute;
                            bottom: -5px;
                            background: #FE972F;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        } 
        .menuButton{
            padding-top: 30px;
            li{
                margin: 35px 0;
            }
        }
    }
    .menuOpen{
        .MobileResponsive{
            transform: translateX(0);
        }
    }
    
    footer {
        overflow: hidden;
        .container {
            .row {
                padding-bottom: 20px;
            }
        }
        padding: 60px 0 40px;
        
        .col-md-3{
            width:100%;
           .logo{
                padding-bottom: 40px;
                text-align: center;
           } 
           .social-icon {
                text-align: center;
                
                img {
                padding: 0 10px;
                }
           }

           .d-none-mobile{
            display: none;
           }
        }
        

        .mobileAcc{
            width:100%;
            .plusMinus-ic{
                position: absolute;
                right: 9px;
                height: 24px;
                width: 24px;
                span{
                    height: 24px;
                    width: 1px;
                    display: inline-block;
                    background: #fff;
                    border-radius: 10px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    &:nth-child(2){
                        width: 24px;
                        height: 1px;
                        display: inline-block;
                        background: #fff;
                        border-radius: 10px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

            }
            .nav {
                overflow: hidden;
                transition: max-height 0.3s ease-out;
                max-height: 0;
                padding-left: 20px;
                padding-bottom: 30px;
                
            }
            .nav.open {
                display: block !important;
                max-height: 1000px; /* or an appropriate max-height value */
            }

        }
        
        .col-md-2 , .footerContact{
            position: relative;
            padding-bottom: 6px;
            padding-top: 15px;
            h3 {
                font-size: 20px;
                font-weight: 500;
            }
            .nav {
                display: none;
                .menu {
                    li {
                        list-style: none;
                        text-align: left;
                        padding-bottom: 6px;
                        a {
                            text-decoration: none;
                            color: #fff;
                            transition: all 0.5s;
                            &:hover{
                                color: #7B91EB;
                            }
                        }
                    }
                }
            }
            &:after{
                content: "";
                background: #7B91EB;
                height: 1px;
                width: calc(100% + 15px);
                position: absolute;
                left: -8px;
                bottom: 8px;
            }
         }
         .d-block-mobile{
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            padding-top: 30px;
           }
         .footer-text {
            padding-top: 9px;
            border-top: none;
            p {
                color: #fff;
                width: 100%;
                max-width: 1200px;
                text-align: center;
                font-size: 14px;
                margin: 0 auto;
                margin-bottom: 20px;
            }
         }
    } 
 
    .businessPosBanner{
        .businessImgPos{
            img{
                width: 100%;
                max-width: 420px !important;
            }
        }
    }    

    .priceFees{
        .row{
            .col-md-4{
                .priceBoxeInner{
                    .priceBoxetop{
                        h3{
                            font-size: 98px;
                            line-height: 92px;
                            padding-top: 35px;
                        }
                    }
                    .priceBoxeMiddle{
                        padding: 20px 20px;
                    }
                }
            }
        }
    }



      
}


@media (max-width:767px){
    .primary_btn {
        height: 40px;
        font-size: 18px;
        line-height: 16px;
    }
    .secondary_btn {
        height: 40px;
        font-size: 18px;
        line-height: 16px;
    }
    h2, .h2 {
        font-size: 36px !important;
        font-weight: 700;
    }
    p {
        font-size: 18px;
    }
    .container {
        padding: 0 20px;
    }
    .heroBanner{
        padding: 150px 0 130px;
        height: auto !important;
        .bannerContent{
            h1{
                font-size: 79px;
                line-height: 69px;
                text-align: center;
            }
            p{
                padding-top: 18px;
                width: 100%;
                max-width: 310px;
                text-align: center;
                font-size: 18px;
                margin: 0 auto;
                padding-bottom: 43px;
            }
        }
    }
    .logoSlider{
        margin-top: -62px;
        .logoSliderWrapper {
            padding: 30px 30px 20px 30px;
            .clientLogoIn  {
                img{
                    width: 100%;
                    padding: 0 10px;
                }
                
            }
        }
    }


    .tradeSettled{
        padding: 70px 0 80px;
        background: none !important;
        .bannerContent{
            text-align: center;
            padding-bottom: 64px;
            .guarnteed_image{
                margin-bottom: -23px;
                padding-top: 6px;
                display: block;
                text-align: center;
                img{
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    position: inherit;
                }
            }
        }
        .tradeForm {
            h4 {
                font-size: 27px;
            }
            p{
                padding-bottom: 10px;
            }
            .form-field{
                input{
                    font-size: 18px;
                    height: 48px;
                    border-radius: 12px;
                }
                select{
                    font-size: 18px;
                    height: 48px;
                    border-radius: 12px;
                }
                .secondary_btn {
                    min-width: 197px;
                    margin: 0 auto;
                }
                .primary_btn {
                    min-width: 197px;
                    margin: 0 auto;
                }
                .orSeprate{
                    position: initial;
                    display: block;
                    text-align: center;
                    color: #0B1C61;
                    font-size: 20px;
                    margin-top: 10px;
                }
            }
            .moreSpace{
                margin-top: 9px;
                margin-bottom: 0;
                text-align: center;
            }
        }
    }

    .customizedAnalysis{
        padding: 60px 0;
        text-align: center;
        .customizedAnalysisContent{
            p{
                padding-bottom: 40px;
            }
        }
    }


    .onlyOtcDesk{
        padding: 70px 0;
        .onlyOtcDeskHeading {
            padding-bottom: 11px;
            h2{
              img{
                width: 100%;
                max-width: 120px;
                margin-top: 10px;
              }

            }
            p{
                padding-top: 20px;
            }
        }
        .row{
            margin: 0 -20px;
            .col-md-4{
                padding: 0 20px;
            }
        }
        .slick-slide{
            padding: 20px 20px 35px 20px;
            >div{
                box-shadow:0px 23px 27px rgb(11 28 97 / 7%);
                border-radius: 20px;
            }
            .otcDeskBox{
                margin-bottom: 0px;
                box-shadow: none;
                .otcDeskBoxTop{
                    border-bottom-left-radius: 80px;
                    border-bottom-right-radius: 80px;
                    img{
                        margin: 0 auto;
                        width: 63px;
                    }
                    h3{
                        font-size: 29px;
                        min-height: 80px;
                    }
                }
                .otcDeskBoxContent{
                    padding: 28px 25px;
                }
            }
        }
        .slick-dots {
            li {
                button {
                    background: rgb(35 67 204 / 5%);
                    border: 1px solid #2343CC;
                    border-radius: 50%;
                    width: 18px; 
                    height: 18px; 
                    padding: 0; 
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background-color: #2343CC;
                    }
                }
            }
        }


        
        .otcDeskBtn{
            margin-top: 64px;
            text-align: center;
            a{
                min-width: 248px;
            }
        }

    }


    .invest-section {
        padding-top:70px;
        padding-bottom:30px;
        position: relative;
        .onlyOtcDeskHeading{
            text-align: center;
            padding: 0 20px;
            h2{
                width: 100%;
                max-width: 500px;
                margin: 0 auto 30px auto;
            }
        }
        .row {
        .col-md-4 {
            .invest-main {
                margin-bottom: 50px;
                h3 {
                    font-size: 32px;
                }
                p {
                    padding-top: 2px;
                }
                .investArrowIcon{
                    margin-bottom: 50px;
                }
            }
            &:nth-child(1){
                .investArrowIcon{
                    img{
                        transform: rotate(64deg);
                        position: relative;
                        left: 15px;
                        width: 140px;
                    }
                }
            }
            &:nth-child(2){
                padding-top:0px;
                .investArrowIcon{
                    img{
                        transform: rotate(90deg);
                        width: 125px;
                    }
                }
            }  
            &:nth-child(3){
                .investArrowIcon{
                    margin-top: 0px;
                    margin-bottom: 0px;
                    img{
                        transform: rotate(117deg);
                        width: 155px;
                    }
                }
            }   
        }
       
      }
      &:before{
        content: "";
        display: none;
      }
    } 
    
    
    .experienced-section {
        padding: 54px 0 60px 0;
    
        .col-md-6 {
            h2 {
                text-align: center;
                font-size: 25px !important;
                padding-bottom: 15px;
            }
            a {
                min-width: 248px;
            }
        }
        .text-end{
            text-align: center !important;
        }    
    
    }


    .signUpMain{
        padding: 90px 0 70px;
        .signUpBox{
            padding: 26px;
            border-radius: 15px;
            .signUpBoxHeader{
                h3{
                    font-size: 26px;
                }
            }
            .primary_btn{
                font-weight: 400;
                font-size: 16px;
            }
            .buttonBtmText{
                padding-top: 13px;
                p{
                    font-size: 14px;
                
                }

            }
            .form-group{
                .form-control{
                    font-size: 16px;
                    border-radius: 10px;
                }
            }

        .remberForgot{
            justify-content: space-between;
            .form-check{
                
                label{
                    line-height: 26px;
                    font-size: 14px !important;
                }
            }
            .remberForgottext{
                a{
                    font-size: 14px !important; 
                }
            }
        }

        }
        .forgotPwdF{
            .forgotPsBtns{
                padding-top: 0;
                p{
                    &:last-child{
                        padding-top: 3px;
                    }
                }
            }
        }
        .otpCode{
            padding: 8px 0 16px 0;
            .form-control{
                height: 54px !important;
            }
        }
        .otpBtnsAll{
            padding-top: 0 !important;
            p{
                &:last-child{
                    padding-top: 3px;
                }
            }
        }


    }

    .personal_Banner{
        padding: 180px 0 50px;
        .bannerContent {
            h1{
                font-size: 52px;
                line-height: 62px;
                text-align: left;
            }
            p{
                padding-top: 13px;
                padding-bottom: 0;
                text-align: left;
                margin: 0;
                max-width: 100%;
            }
        }
    }
    .key-reason-section{
        padding: 65px 0;
        .onlyOtcDeskHeading{
            h2{
                line-height: 47px;
            }
        }
        .row{
            margin-top: 50px;
        }
        .key_reason-boxes {
            background: #fff;
            position: relative;
            padding-left: 25px;
            border-radius: 17px;
            padding-top: 25px;
            padding-right: 25px;
            padding-bottom: 25px;
            height: 100%;
            box-shadow: 0px 14px 40.7px rgba(11, 28, 97, 0.15);
            margin-bottom: 40px;
        }
    }

    .personal_exp-btn{
        h4{
            font-size: 23px;
            line-height: 32px;
            margin-bottom: 40px;
        }
        .col-md-4{
            a{
                min-width: 315px;
                height: 45px;
                line-height: 26px;
            }
        }
    }
    .key-reason-section{
        .mt-30 {
            margin-top: 30px;
        }
        .slick-dots  {
            li{
                button{
                    background: rgba(35, 67, 204, 0.05);
                    border: 1px solid #2343CC;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background-color: #2343CC;
                    }
                }
            }
            
        }
    }
    
.optimised_trading{
    padding: 60px 0;
    .onlyOtcDeskHeading{
        padding-bottom: 40px;
    }
    .optimised_blue{
        padding: 40px 25px 42px 25px;
        border-radius: 10px;
        margin-bottom: 25px;
        h4{
            font-size: 24px;
            line-height: 32px;
        }
        p{
            margin-bottom: 0;
        }
    }
}

.unleashing_crypto-h{
    .key_reason-boxes{
        h4{
            font-size: 23px;
            font-weight: 700;
            line-height: 30px;
            padding-bottom: 7px;
        }
    }
}
    
.tradeSettled {
    .bannerContent{
        padding-bottom: 44px;
        h2{
            padding-bottom: 15px;
        }
    }
}

.how-it-works{
    text-align: center;
    padding-top: 54px;
    .how-it-image {
        margin-top: 30px;
    }
    .order-f-first{
        order: -1;
    }
}


.advanced_transition{
    .slick-dots{
        li{
            button{
                background: #EBF0FF;
            }
        }
    }
    .mt-30{
        margin-top: 60px;
    }
}


.aboutBanner{
    padding-bottom: 50px;
    .bannerContent{
        padding: 10px 0 20px 0;
        h1{
            font-size: 40px;
            line-height: 57px;
            text-align: left;
        }
        p{
            text-align: left;
            max-width: 100%;
            padding-bottom: 0;
        }
    }
}



.streamlined_sec{
    background-color: #EBF0FF;
    padding: 65px 0 35px 0;
    .row{
        margin-top: 0px !important;
        .col-md-6{
            .Streamlined_image{
                margin-bottom: 0px;
                img{
                    left: 0px;
                }
            }
            .onlyOtcDeskHeading{
                h2{
                    text-align: left;
                }
                p{
                    text-align: left;
                }
            }
            &:last-child{
                order: -1;
            }
        }

    }
}

.mapAboutFooter{
    &>img{
        height: 400px;
        object-fit: cover;
    }
    .mapAddress{
        max-width: 203px;
        h4{
            font-size: 17px;
            margin: 16px 0;
        }
    }
}

.automatedStream{
    .how-it-bannerContent{
        h2{
           text-align: left;
        }
        p{
            text-align: left;
        }
    }
}


.what-twofa{
    padding: 60px 0;
    .order-f-first{
        order: -1;
    }
}


.herobanner_two{
    padding: 150px 0 40px;
    .bannerContent{
        h1{
            font-size: 50px;
            line-height: 65px;
            text-align: left;
        }
        p{
            padding-bottom: 0;
            text-align: left;
        }
    }
}


.ensure_security{
    padding: 50px 0;
    .onlyOtcDeskHeading{
        padding-bottom: 0px;
        h2{
            line-height: 50px;
            text-align: left;
        }
        p{
            text-align: left;
        }
    }
}

.what-twofa{
    .customizedAnalysisImg{
        margin-top: 30px;
    }
}

.how-two-work{
    padding: 60px 0 20px;
    .onlyOtcDeskHeading {
        padding-bottom: 20px;
    }
}

.benifits_work2fa{
    .onlyOtcDeskHeading{
        h2{
            text-align: left;
        }
    }
}

.our_commint-sec{
    .onlyOtcDeskHeading {
        h2{
            text-align: left;
            line-height: 40px;
            margin-bottom: 0;
        }
    }
}


.howsetup_fa{
    padding: 70px 0 48px;
    .otcDeskBox{
        h3{
            font-size: 60px;
            min-height: 33px;
            margin: 5px 0 0 0;
        }
    }
    .otcDeskBox{
        margin-bottom: 30px;
        .otcDeskBoxContent{
            padding: 28px 25px 35px 25px;
            min-height: auto;       
        }
    }
    .col-md-3{
        &:first-child{
            top: 0;
        }
        &:nth-child(3){
            top: 0;
        }
    }

}


.billingBannerHero{
    .bannerContent{
        h1{
            font-size: 55px;
            line-height: 64px;
            text-align: left;
            word-break: break-word;
        }
        p{
            margin: 0px;
        }
    }
}

.billing_intro{
    .onlyOtcDeskHeading {
        text-align: left !important;
        padding-bottom: 0;
        h2{
            line-height: 47px;
        }
    }
}

.billingBenefits{
    .onlyOtcDeskHeading {
        h2{
            br{
                display: none;
            }
        }
    }
}


.unlockBusinesss{
    .key-p-slider{
        .slick-dots{
            margin-bottom: 11px;
            li{
                button{
                    background: #EBF0FF;
                }
                &.slick-active {
                    button {
                        background: #2343CC !important;
                    }
                }
            }
            
        }
    }
}

.businessPosBanner{
    padding-bottom: 0px;
    flex-wrap: wrap;
    .bannerContent{
        h1{
            text-align: left;
            font-size: 68px;
        }
        p{
            text-align: left;
            max-width: 100%;
        }
    }
    .businessImgPos{
        position: initial;
        img{
            width: 100%;
            max-width: 420px !important;
        }
    }
} 

.posTerminialInt{
    .onlyOtcDeskHeading {
        text-align: left !important;
    }
}

.posTerminialInt{
    padding-bottom: 56px;
}

.benifitsPosSec{
    .onlyOtcDeskHeading{
        h2{
            text-align: left;
            br{
                display:none;
            }
        }
    }
}


.feesBanner{
    padding: 150px 0 20px;
    .bannerContent{
        h1{
            font-size: 52px;
            line-height: 67px;
            text-align: left;
        }
        p{
            text-align: left;
        }
    }
}

.keyFeesWhite{
    padding: 60px 0 80px 0;
    .onlyOtcDeskHeading{
        h2{
            text-align: left;
            line-height: 50px;
        }
        p{
            text-align: left;
        }
    }
}

.feesKeyFeatures{
    .onlyOtcDeskHeading{
        h2{
            text-align: left;
        }
        p{
            text-align: left;
        }
    }
}

.elevateCrypto{
    table{
        thead{
            th{
                font-size: 10px;
                padding: 14px 17px;
                img{
                    width: 90px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 10px;
                    padding: 7px 8px;
                    vertical-align: bottom;
                    img{
                        width: 15px;
                    }
                }
            }
        }
    }
}

.emailverification{
    .signUpBoxHeader{
        h3{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        img{
            width: 95px;
        }
    }
    .signUpForm{
        padding-bottom: 9px;
        p{
            font-size: 16px;
            margin: 0px 0;
        }
    }
    .buttonBtmText{
        p{
            a{
                display: block;
            }
        }
    }
}

.priceFees{
    padding: 70px 0 50px 0;
    .onlyOtcDeskHeading{
        margin-bottom: 45px;
        h2{
            text-align: left;
        }
        p{
            text-align: left;
        }
    }

    .row{
        .col-md-4{
            padding: 0px 15px;
            margin-bottom: 30px;
            .priceBoxeInner{
                position: relative;
                .priceBoxetop{
                    h5{
                        font-size: 24px;
                        font-weight: 700;
                    }
                    span{
                        font-size: 16px;
                    }
                    h3{
                        font-size: 118px;
                        line-height: 122px;
                        padding-top: 25px;
                    }
                }
                .priceBoxeMiddle{
                    padding: 15px 19px;
                    margin-bottom: 19px;
                    margin-top: 30px;
                }
                p{
                    font-size: 16px;
                    color: #616161;
                    
                }
            }
            .priceBtnsF{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 40px;
                z-index: 99;
            }
            &:nth-child(1){
               margin-right: 0; 
            }
            &:nth-child(2){
                .priceBoxeInner{
                    height: 100%;
                    position: relative;
                    top: 0;
                    z-index: 9;
                }
            }
            &:nth-child(3){
                margin-left: 0; 
            }
        }
    }
}


.feesFaq{
    padding: 60px 0 55px;
    .onlyOtcDeskHeading{
        h2{
            text-align: left;
        }
    }
    #accordionExample{
        .accordion-header{
            button{
                font-size: 18px;
                padding: 20px 50px 20px 20px;
                &:before{
                    height: 30px !important ;
                    width: 30px !important;
                }
                &:after{
                    position: relative;
                    left: -2px !important;
                    bottom: -8px !important;
                    background-size: 14px !important;
                }
            }
            button[aria-expanded=true]:after{
                bottom: 4px !important;
                left: -7px !important;
            }
        }
        .accordion-body{
            font-size: 16px;
            padding-bottom: 20px;
        }
    }
}


#msform{
    #progressbar{
        li{
            span{
                height: 29px !important;;
                width: 29px !important;;
                border: 4px solid #C6C8CF !important;;
                &:after{
                    height: 13px !important;
                    width: 13px !important;
                    top: 4px !important;
                    left: 4px !important;
                }
            }
            strong{
                font-size: 12px;
                line-height: 18px !important;
            }
            &:after{
                top: 13px !important;;
                height: 4px !important;;
            }
            &.complete{
                span{
                    background-color: #2343CC;
                    box-shadow: none;
                    border: transparent !important;
                    &::before{
                        content: "";
                        background-size: 12px !important; 
                    }
                }
            }
        }
    }
    fieldset{
        .form-group{
            margin-bottom: 17px;
        }
    }
    .firstStepB{
        button{
            margin-left: 0px !important;
            min-width: 100% !important;
        }
    }
}

.formCheckB, .formCustomRadio{
    padding: 0 7px !important;
}

.customRadio {
    margin-bottom: 12px;
    label{
        &:before{
            width: 20px;
            height: 20px;
        }
        &:after{
            width: 9px;
            height: 9px;
            top: 9px;
            left: -28px;
        }
    }
}
.customCheckbox{
    margin-bottom: 12px;
    label{
        
        &:before{
            width: 20px;
            height: 20px;
        }
        &:after{
            width: 14px;
            height: 9px;
            top: 8px;
            left: -30px;
        }
    }
}

.customImgCheckbox{
    label{
        font-size: 16px;
        padding: 9px;
        span{
            width: 35px;
            height: 35px;
            margin-right: 6px;
        }
    }
}
.formStepFooter{
    display: flex;
    padding-top: 23px;
    margin-top: 22px;
    justify-content: space-between;
    margin: 0 -5px;
    button{
        min-width: inherit;
        margin: 0 5px;
        min-width: 130px;
    }
}
.successCongImg {
    img{
        width: 110px;
    }
    h3{
        font-size: 27px;
    }
}


}









